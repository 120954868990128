
export const ImageData=[
   '1.jpeg',
   '2.jpeg',
   '3.jpg',
   '4.jpeg',
   '5.jpeg',
   '6.jpeg',
   '7.jpeg',
   '8.jpeg',
   '9.jpeg',
   '10.jpeg',
   '11.jpg',
   '12.jpg',
   '13.jpg',
   '14.jpeg',
   '15.jpg',
   '16.jpeg',
   '17.jpg',
   '18.jpg'

]